import React, { useEffect, useState } from "react";
import cx from "classnames";

import { useDispatch, useSelector } from "react-redux";
import { getImage, selectCacheImages } from "store/slices/image";
import styles from "./index.module.scss";

const ImageContainer = ({ imageUuid }) => {
  const [image, setImage] = useState("");
  const cachedImages = useSelector(selectCacheImages);
  const dispatch = useDispatch();

  const cacheImages = (imgUuid) => {
    if (cachedImages && cachedImages[imgUuid]) {
      setImage(cachedImages[imgUuid]);
    } else {
      dispatch(getImage(imageUuid))
        .unwrap()
        .then((res) => {
          setImage(res.imageData);
        });
    }
  };

  useEffect(() => {
    cacheImages(imageUuid);
  }, [imageUuid, cachedImages]);

  return (
    <div
      className={cx(styles["box"], {
        [styles["fallback-bg"]]: !image,
      })}
    >
      {image && <img src={`data:image/jpeg;base64,${image}`} alt={imageUuid} />}
    </div>
  );
};

export default React.memo(ImageContainer);
